import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    keywords: [
      { id: 0, name: "Mountain" },
      { id: 1, name: "Water" },
      { id: 2, name: "City" },
      { id: 3, name: "Sunrise" },
      { id: 4, name: "Sunset" },
      { id: 5, name: "Reflection" }
    ],
    blogs: [
      {
        id: 1,
        blogcommand: "header",
        blogtext: "Start of new blog and website !"
      },
      { id: 2, blogcommand: "text", blogtext: "Late October 2020" },
      { id: 3, blogcommand: "picture", blogtext: "Aviemore-1000" },
      {
        id: 4,
        blogcommand: "text",
        blogtext: "Loch Morlich - near Aviemore - one of my favorite places."
      },
      {
        id: 5,
        blogcommand: "text",
        blogtext: "great for sunrise and sunsets...."
      }
    ],
    galleries: [
      { id: 999, name: "Cornwall-1000", description: "All" },
      { id: 0, name: "Aviemore-1000", description: "Aviemore" },
      { id: 1, name: "Bridges-1000", description: "Bridges" },
      { id: 2, name: "Cornwall-1000", description: "Cornwall" },
      { id: 3, name: "Dorset-1000", description: "Dorset" },
      { id: 4, name: "Edinburgh-1000", description: "Edinburgh" },
      { id: 5, name: "Fife-1000", description: "Fife" },
      { id: 6, name: "Lakedistrict-1000", description: "Lakedistrict" },
      { id: 7, name: "Moray-1000", description: "Moray" },
      { id: 8, name: "Northumberland-1000", description: "Northumberland" },
      { id: 9, name: "Pitlochry-1000", description: "Pitlochry" },
      { id: 10, name: "Yorkshire-1000", description: "Yorkshire" }
    ],
    photos: [
      {
        id: 1000,
        name: "Aviemore-1000",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1001,
        name: "Aviemore-1001",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1002,
        name: "Aviemore-1002",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1003,
        name: "Aviemore-1003",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1004,
        name: "Aviemore-1004",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1005,
        name: "Aviemore-1005",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1006,
        name: "Aviemore-1006",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1007,
        name: "Aviemore-1007",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1008,
        name: "Aviemore-1008",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1009,
        name: "Aviemore-1009",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1010,
        name: "Aviemore-1010",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1011,
        name: "Aviemore-1011",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1012,
        name: "Aviemore-1012",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1013,
        name: "Aviemore-1013",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1014,
        name: "Aviemore-1014",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1015,
        name: "Aviemore-1015",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1016,
        name: "Aviemore-1016",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1017,
        name: "Aviemore-1017",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1018,
        name: "Aviemore-1018",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1019,
        name: "Aviemore-1019",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1020,
        name: "Aviemore-1020",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1021,
        name: "Aviemore-1021",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1022,
        name: "Aviemore-1022",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1023,
        name: "Aviemore-1023",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1024,
        name: "Aviemore-1024",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1025,
        name: "Aviemore-1025",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1026,
        name: "Aviemore-1026",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1027,
        name: "Aviemore-1027",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1028,
        name: "Aviemore-1028",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1029,
        name: "Aviemore-1029",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1030,
        name: "Aviemore-1030",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1031,
        name: "Aviemore-1031",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1032,
        name: "Aviemore-1032",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1033,
        name: "Aviemore-1033",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1034,
        name: "Aviemore-1034",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1035,
        name: "Aviemore-1035",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1036,
        name: "Aviemore-1036",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1037,
        name: "Aviemore-1037",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1038,
        name: "Aviemore-1038",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1039,
        name: "Aviemore-1039",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1040,
        name: "Aviemore-1040",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1041,
        name: "Aviemore-1041",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1042,
        name: "Aviemore-1042",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1043,
        name: "Aviemore-1043",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1044,
        name: "Aviemore-1044",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1045,
        name: "Aviemore-1045",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1046,
        name: "Aviemore-1046",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1047,
        name: "Aviemore-1047",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1048,
        name: "Aviemore-1048",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1049,
        name: "Aviemore-1049",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1050,
        name: "Aviemore-1050",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1051,
        name: "Aviemore-1051",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1052,
        name: "Aviemore-1052",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1053,
        name: "Aviemore-1053",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1054,
        name: "Aviemore-1054",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1055,
        name: "Aviemore-1055",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1056,
        name: "Aviemore-1056",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1057,
        name: "Aviemore-1057",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1058,
        name: "Aviemore-1058",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1059,
        name: "Aviemore-1059",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1060,
        name: "Aviemore-1060",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1061,
        name: "Aviemore-1061",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1062,
        name: "Aviemore-1062",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1063,
        name: "Aviemore-1063",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1064,
        name: "Aviemore-1064",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1065,
        name: "Aviemore-1065",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1066,
        name: "Aviemore-1066",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1067,
        name: "Aviemore-1067",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1068,
        name: "Aviemore-1068",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1069,
        name: "Aviemore-1069",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1070,
        name: "Aviemore-1070",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1071,
        name: "Aviemore-1071",
        description: "Type in unused description here",
        galleryid: 0
      },
      {
        id: 1072,
        name: "Bridges-1000",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1073,
        name: "Bridges-1001",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1074,
        name: "Bridges-1002",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1075,
        name: "Bridges-1003",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1076,
        name: "Bridges-1004",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1077,
        name: "Bridges-1005",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1078,
        name: "Bridges-1006",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1079,
        name: "Bridges-1007",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1080,
        name: "Bridges-1008",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1081,
        name: "Bridges-1009",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1082,
        name: "Bridges-1010",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1083,
        name: "Bridges-1011",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1084,
        name: "Bridges-1012",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1085,
        name: "Bridges-1013",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1086,
        name: "Bridges-1014",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1087,
        name: "Bridges-1015",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1088,
        name: "Bridges-1016",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1089,
        name: "Bridges-1017",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1090,
        name: "Bridges-1018",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1091,
        name: "Bridges-1019",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1092,
        name: "Bridges-1020",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1093,
        name: "Bridges-1021",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1094,
        name: "Bridges-1022",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1095,
        name: "Bridges-1023",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1096,
        name: "Bridges-1024",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1097,
        name: "Bridges-1025",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1098,
        name: "Bridges-1026",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1099,
        name: "Bridges-1027",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1100,
        name: "Bridges-1028",
        description: "Type in unused description here",
        galleryid: 1
      },
      {
        id: 1101,
        name: "Cornwall-1000",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1102,
        name: "Cornwall-1001",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1103,
        name: "Cornwall-1002",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1104,
        name: "Cornwall-1003",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1105,
        name: "Cornwall-1004",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1106,
        name: "Cornwall-1005",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1107,
        name: "Cornwall-1006",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1108,
        name: "Cornwall-1007",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1109,
        name: "Cornwall-1008",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1110,
        name: "Cornwall-1009",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1111,
        name: "Cornwall-1010",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1112,
        name: "Cornwall-1011",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1113,
        name: "Cornwall-1012",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1114,
        name: "Cornwall-1013",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1115,
        name: "Cornwall-1014",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1116,
        name: "Cornwall-1015",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1117,
        name: "Cornwall-1016",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1118,
        name: "Cornwall-1017",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1119,
        name: "Cornwall-1018",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1120,
        name: "Cornwall-1019",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1121,
        name: "Cornwall-1020",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1122,
        name: "Cornwall-1021",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1123,
        name: "Cornwall-1022",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1124,
        name: "Cornwall-1023",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1125,
        name: "Cornwall-1024",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1126,
        name: "Cornwall-1025",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1127,
        name: "Cornwall-1026",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1128,
        name: "Cornwall-1027",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1129,
        name: "Cornwall-1028",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1130,
        name: "Cornwall-1029",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1131,
        name: "Cornwall-1030",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1132,
        name: "Cornwall-1031",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1133,
        name: "Cornwall-1032",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1134,
        name: "Cornwall-1033",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1135,
        name: "Cornwall-1034",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1136,
        name: "Cornwall-1035",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1137,
        name: "Cornwall-1036",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1138,
        name: "Cornwall-1037",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1139,
        name: "Cornwall-1038",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1140,
        name: "Cornwall-1039",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1141,
        name: "Cornwall-1040",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1142,
        name: "Cornwall-1041",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1143,
        name: "Cornwall-1042",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1144,
        name: "Cornwall-1043",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1145,
        name: "Cornwall-1044",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1146,
        name: "Cornwall-1045",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1147,
        name: "Cornwall-1046",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1148,
        name: "Cornwall-1047",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1149,
        name: "Cornwall-1048",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1150,
        name: "Cornwall-1049",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1151,
        name: "Cornwall-1050",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1152,
        name: "Cornwall-1051",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1153,
        name: "Cornwall-1052",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1154,
        name: "Cornwall-1053",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1155,
        name: "Cornwall-1054",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1156,
        name: "Cornwall-1055",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1157,
        name: "Cornwall-1056",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1158,
        name: "Cornwall-1057",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1159,
        name: "Cornwall-1058",
        description: "Type in unused description here",
        galleryid: 2
      },
      {
        id: 1160,
        name: "Dorset-1000",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1161,
        name: "Dorset-1001",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1162,
        name: "Dorset-1002",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1163,
        name: "Dorset-1003",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1164,
        name: "Dorset-1004",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1165,
        name: "Dorset-1005",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1166,
        name: "Dorset-1006",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1167,
        name: "Dorset-1007",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1168,
        name: "Dorset-1008",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1169,
        name: "Dorset-1009",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1170,
        name: "Dorset-1010",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1171,
        name: "Dorset-1011",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1172,
        name: "Dorset-1012",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1173,
        name: "Dorset-1013",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1174,
        name: "Dorset-1014",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1175,
        name: "Dorset-1015",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1176,
        name: "Dorset-1016",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1177,
        name: "Dorset-1017",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1178,
        name: "Dorset-1018",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1179,
        name: "Dorset-1019",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1180,
        name: "Dorset-1020",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1181,
        name: "Dorset-1021",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1182,
        name: "Dorset-1022",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1183,
        name: "Dorset-1023",
        description: "Type in unused description here",
        galleryid: 3
      },
      {
        id: 1184,
        name: "Edinburgh-1000",
        description: "Type in unused description here",
        galleryid: 4
      },
      {
        id: 1185,
        name: "Edinburgh-1001",
        description: "Type in unused description here",
        galleryid: 4
      },
      {
        id: 1186,
        name: "Edinburgh-1002",
        description: "Type in unused description here",
        galleryid: 4
      },
      {
        id: 1187,
        name: "Edinburgh-1003",
        description: "Type in unused description here",
        galleryid: 4
      },
      {
        id: 1188,
        name: "Edinburgh-1004",
        description: "Type in unused description here",
        galleryid: 4
      },
      {
        id: 1189,
        name: "Edinburgh-1005",
        description: "Type in unused description here",
        galleryid: 4
      },
      {
        id: 1190,
        name: "Edinburgh-1006",
        description: "Type in unused description here",
        galleryid: 4
      },
      {
        id: 1191,
        name: "Fife-0382",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1192,
        name: "Fife-0383",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1193,
        name: "Fife-0385",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1194,
        name: "Fife-0386",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1195,
        name: "Fife-0387",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1196,
        name: "Fife-0388",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1197,
        name: "Fife-0389",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1198,
        name: "Fife-0390",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1199,
        name: "Fife-0391",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1200,
        name: "Fife-1000",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1201,
        name: "Fife-1001",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1202,
        name: "Fife-1002",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1203,
        name: "Fife-1003",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1204,
        name: "Fife-1004",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1205,
        name: "Fife-1005",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1206,
        name: "Fife-1006",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1207,
        name: "Fife-1007",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1208,
        name: "Fife-1008",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1209,
        name: "Fife-1009",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1210,
        name: "Fife-1010",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1211,
        name: "Fife-1011",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1212,
        name: "Fife-1012",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1213,
        name: "Fife-1013",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1214,
        name: "Fife-1014",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1215,
        name: "Fife-1015",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1216,
        name: "Fife-1016",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1217,
        name: "Fife-1017",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1218,
        name: "Fife-1018",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1219,
        name: "Fife-1019",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1220,
        name: "Fife-1020",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1221,
        name: "Fife-1021",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1222,
        name: "Fife-1022",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1223,
        name: "Fife-1023",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1224,
        name: "Fife-1024",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1225,
        name: "Fife-1025",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1226,
        name: "Fife-1026",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1227,
        name: "Fife-1027",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1228,
        name: "Fife-1028",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1229,
        name: "Fife-1029",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1230,
        name: "Fife-1030",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1231,
        name: "Fife-1031",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1232,
        name: "Fife-1032",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1233,
        name: "Fife-1033",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1234,
        name: "Fife-1034",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1235,
        name: "Fife-1035",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1236,
        name: "Fife-1036",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1237,
        name: "Fife-1037",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1238,
        name: "Fife-1038",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1239,
        name: "Fife-1039",
        description: "Type in unused description here",
        galleryid: 5
      },
      {
        id: 1240,
        name: "Lakedistrict-1000",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1241,
        name: "Lakedistrict-1001",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1242,
        name: "Lakedistrict-1002",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1243,
        name: "Lakedistrict-1003",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1244,
        name: "Lakedistrict-1004",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1245,
        name: "Lakedistrict-1005",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1246,
        name: "Lakedistrict-1006",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1247,
        name: "Lakedistrict-1007",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1248,
        name: "Lakedistrict-1008",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1249,
        name: "Lakedistrict-1009",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1250,
        name: "Lakedistrict-1010",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1251,
        name: "Lakedistrict-1011",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1252,
        name: "Lakedistrict-1012",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1253,
        name: "Lakedistrict-1013",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1254,
        name: "Lakedistrict-1014",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1255,
        name: "Lakedistrict-1015",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1256,
        name: "Lakedistrict-1016",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1257,
        name: "Lakedistrict-1017",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1258,
        name: "Lakedistrict-1018",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1259,
        name: "Lakedistrict-1019",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1260,
        name: "Lakedistrict-1020",
        description: "Type in unused description here",
        galleryid: 6
      },
      {
        id: 1261,
        name: "Moray-1000",
        description: "Type in unused description here",
        galleryid: 7
      },
      {
        id: 1262,
        name: "Moray-1001",
        description: "Type in unused description here",
        galleryid: 7
      },
      {
        id: 1263,
        name: "Moray-1002",
        description: "Type in unused description here",
        galleryid: 7
      },
      {
        id: 1264,
        name: "Moray-1003",
        description: "Type in unused description here",
        galleryid: 7
      },
      {
        id: 1265,
        name: "Moray-1004",
        description: "Type in unused description here",
        galleryid: 7
      },
      {
        id: 1266,
        name: "Moray-1005",
        description: "Type in unused description here",
        galleryid: 7
      },
      {
        id: 1267,
        name: "Moray-1006",
        description: "Type in unused description here",
        galleryid: 7
      },
      {
        id: 1268,
        name: "Moray-1007",
        description: "Type in unused description here",
        galleryid: 7
      },
      {
        id: 1269,
        name: "Moray-1008",
        description: "Type in unused description here",
        galleryid: 7
      },
      {
        id: 1270,
        name: "Moray-1009",
        description: "Type in unused description here",
        galleryid: 7
      },
      {
        id: 1271,
        name: "Northumberland-1000",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1272,
        name: "Northumberland-1001",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1273,
        name: "Northumberland-1002",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1274,
        name: "Northumberland-1003",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1275,
        name: "Northumberland-1004",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1276,
        name: "Northumberland-1005",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1277,
        name: "Northumberland-1006",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1278,
        name: "Northumberland-1007",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1279,
        name: "Northumberland-1008",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1280,
        name: "Northumberland-1009",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1281,
        name: "Northumberland-1010",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1282,
        name: "Northumberland-1011",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1283,
        name: "Northumberland-1012",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1284,
        name: "Northumberland-1013",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1285,
        name: "Northumberland-1014",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1286,
        name: "Northumberland-1015",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1287,
        name: "Northumberland-1016",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1288,
        name: "Northumberland-1017",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1289,
        name: "Northumberland-1018",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1290,
        name: "Northumberland-1019",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1291,
        name: "Northumberland-1020",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1292,
        name: "Northumberland-1021",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1293,
        name: "Northumberland-1022",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1294,
        name: "Northumberland-1023",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1295,
        name: "Northumberland-1024",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1296,
        name: "Northumberland-1025",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1297,
        name: "Northumberland-1026",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1298,
        name: "Northumberland-1027",
        description: "Type in unused description here",
        galleryid: 8
      },
      {
        id: 1299,
        name: "Pitlochry-1000",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1300,
        name: "Pitlochry-1001",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1301,
        name: "Pitlochry-1002",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1302,
        name: "Pitlochry-1003",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1303,
        name: "Pitlochry-1004",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1304,
        name: "Pitlochry-1005",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1305,
        name: "Pitlochry-1006",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1306,
        name: "Pitlochry-1007",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1307,
        name: "Pitlochry-1008",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1308,
        name: "Pitlochry-1009",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1309,
        name: "Pitlochry-1010",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1310,
        name: "Pitlochry-1011",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1311,
        name: "Pitlochry-1012",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1312,
        name: "Pitlochry-1013",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1313,
        name: "Pitlochry-1014",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1314,
        name: "Pitlochry-1015",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1315,
        name: "Pitlochry-1016",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1316,
        name: "Pitlochry-1017",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1317,
        name: "Pitlochry-1018",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1318,
        name: "Pitlochry-1019",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1319,
        name: "Pitlochry-1020",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1320,
        name: "Pitlochry-1021",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1321,
        name: "Pitlochry-1022",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1322,
        name: "Pitlochry-1023",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1323,
        name: "Pitlochry-1024",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1324,
        name: "Pitlochry-1025",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1325,
        name: "Pitlochry-1026",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1326,
        name: "Pitlochry-1027",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1327,
        name: "Pitlochry-1028",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1328,
        name: "Pitlochry-1029",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1329,
        name: "Pitlochry-1030",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1330,
        name: "Pitlochry-1031",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1331,
        name: "Pitlochry-1032",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1332,
        name: "Pitlochry-1033",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1333,
        name: "Pitlochry-1034",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1334,
        name: "Pitlochry-1035",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1335,
        name: "Pitlochry-1036",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1336,
        name: "Pitlochry-1037",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1337,
        name: "Pitlochry-1038",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1338,
        name: "Pitlochry-1039",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1339,
        name: "Pitlochry-1040",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1340,
        name: "Pitlochry-1041",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1341,
        name: "Pitlochry-1042",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1342,
        name: "Pitlochry-1043",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1343,
        name: "Pitlochry-1044",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1344,
        name: "Pitlochry-1045",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1345,
        name: "Pitlochry-1046",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1346,
        name: "Pitlochry-1047",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1347,
        name: "Pitlochry-1048",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1348,
        name: "Pitlochry-1049",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1349,
        name: "Pitlochry-1050",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1350,
        name: "Pitlochry-1051",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1351,
        name: "Pitlochry-1052",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1352,
        name: "Pitlochry-1053",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1353,
        name: "Pitlochry-1054",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1354,
        name: "Pitlochry-1055",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1355,
        name: "Pitlochry-1056",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1356,
        name: "Pitlochry-1057",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1357,
        name: "Pitlochry-1058",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1358,
        name: "Pitlochry-1059",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1359,
        name: "Pitlochry-1060",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1360,
        name: "Pitlochry-1061",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1361,
        name: "Pitlochry-1062",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1362,
        name: "Pitlochry-1063",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1363,
        name: "Pitlochry-1064",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1364,
        name: "Pitlochry-1065",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1365,
        name: "Pitlochry-1066",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1366,
        name: "Pitlochry-1067",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1367,
        name: "Pitlochry-1068",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1368,
        name: "Pitlochry-1069",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1369,
        name: "Pitlochry-1070",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1370,
        name: "Pitlochry-1071",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1371,
        name: "Pitlochry-1072",
        description: "Type in unused description here",
        galleryid: 9
      },
      {
        id: 1372,
        name: "Yorkshire-1000",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1373,
        name: "Yorkshire-1001",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1374,
        name: "Yorkshire-1002",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1375,
        name: "Yorkshire-1003",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1376,
        name: "Yorkshire-1004",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1377,
        name: "Yorkshire-1005",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1378,
        name: "Yorkshire-1006",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1379,
        name: "Yorkshire-1007",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1380,
        name: "Yorkshire-1008",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1381,
        name: "Yorkshire-1009",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1382,
        name: "Yorkshire-1010",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1383,
        name: "Yorkshire-1011",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1384,
        name: "Yorkshire-1012",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1385,
        name: "Yorkshire-1013",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1386,
        name: "Yorkshire-1014",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1387,
        name: "Yorkshire-1015",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1388,
        name: "Yorkshire-1016",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1389,
        name: "Yorkshire-1017",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1390,
        name: "Yorkshire-1018",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1391,
        name: "Yorkshire-1019",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1392,
        name: "Yorkshire-1020",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1393,
        name: "Yorkshire-1021",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1394,
        name: "Yorkshire-1022",
        description: "Type in unused description here",
        galleryid: 10
      },
      {
        id: 1395,
        name: "Yorkshire-1023",
        description: "Type in unused description here",
        galleryid: 10
      }
    ]
  },
  mutations: {},
  getters: {
    allblogs: state => state.blogs,
    allgalleries: state => state.galleries,
    allphotos: state => state.photos,
    allkeywords: state => state.keywords,
    photosbygalleryID: state => id => {
      return state.photos.filter(photo => photo.galleryid === id);
    }
  },
  actions: {},
  modules: {}
});
