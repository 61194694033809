<template>
  <v-toolbar>
    <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn to="/blog" text>Blog</v-btn>
    <v-spacer></v-spacer>
    <v-btn to="/gallery" text>Gallery</v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: "AppNavigation",
  data() {
    return {
      appTitle: "Photos by Roderick",
      drawer: false,
      items: [{ title: "Blog" }, { title: "Gallery" }]
    };
  }
};
</script>

<style scoped></style>
